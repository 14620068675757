.home-card {
  border: none;

  h4 {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .homepage-gallery-item-image {
    height: 200px !important;
  }
}

