.gallery-row {
  width: 80%;
  margin: auto;
}

.provider-gallery-page {
  .gallery-row h4 {
    font-size: 20px !important;
  }
}
