/* Font Imports */

@font-face {
  font-family: 'Enterprise-Light';
  src: url('./fonts/EnterpriseSansVF-Regular.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Enterprise';
  src: url('./fonts/EnterpriseSansVF-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Enterprise-Medium';
  src: url('./fonts/EnterpriseSansVF-Regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Enterprise-Bold';
  src: url('./fonts/EnterpriseSansVF-Regular.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Body Classes - set a max-width for horizontal fluid layout and make it centered */

html {

  --primary-branding-color: #316bbe;
  --color-1: #EFEFEE; //light grey

  background-color: #efefee;
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
  border-left: 1px solid #d0d0ce;
  border-right: 1px solid #d0d0ce;
}

body {
  margin-right: auto;
  margin-left: auto;
  max-width: 1550px;
  overflow-x: hidden;
  z-index: -1001;
}

.content-max {
  max-width: 1350px;
}

section {
  padding: 0;
}

body {
  background-color: #fff;
}

/* Typography */
h1 {
  color: #282A2E;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-size: 40px !important;
  letter-spacing: .04rem !important;
  font-weight: normal !important;
  line-height: 1 !important;
  text-rendering: optimizelegibility;
}

h2 {
  color: #282A2E;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-size: 36px !important;
  letter-spacing: .04rem !important;
  font-weight: normal !important;
  line-height: 45px !important;
  text-rendering: optimizelegibility;
}

h3 {
  color: #282A2E;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-size: 36px !important;
  letter-spacing: .04rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  text-rendering: optimizelegibility;
}

h4 {
  color: #282A2E;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-size: 23px !important;
  letter-spacing: .04rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  text-rendering: optimizelegibility;
}

h5 {
  color: #282A2E;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-size: 16px !important;
  letter-spacing: .04rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  text-rendering: optimizelegibility;
}

.small-text {
  font-size: 12px;
}

b {
  font-weight: bolder !important;
}


main {
  min-height: 100%;
}

main {
  position: relative; /* needed if you position the pseudo-element absolutely */
}

/*Optum Heading Red Dash */
.dash {
  position: relative;
  padding-bottom: 39px !important;
  margin-bottom: 0 !important
}

.dash:after {
  content: '';
  bottom: 18px;
  position: absolute;
  height: 3px;
  width: 60px;
  background-color: var(--primary-branding-color);
  left: 0
}

.dash.align--center:after {
  left: 50% !important;
  margin-left: -30px !important
}

/*Optum Link Hover */
a.button-link {
  color: #316bbe;
  font-family: "Enterprise-Bold";
  margin-right: 14px;
  font-size: 16px;
  background: 0;
  border: 0;
  text-decoration: none;
  -webkit-transition: -webkit-transform .1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: -webkit-transform .1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform .1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform .1s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform .1s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: relative
}

a.button-link::before {
  -webkit-transition: -webkit-transform .3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: -webkit-transform .3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform .3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform .3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform .3s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 100%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  position: absolute;
  bottom: -4px;
  content: '';
  border-bottom: 2px solid transparent
}

a.button-link::after {
  content: '\f054';
  position: absolute;
  right: -3px;
  font-size: 14px;
  bottom: -2px;
  font-family: "Font Awesome 5 Free";
  width: 0;
  font-weight: 900;
}

a.button-link:active, a.button-link:hover, a.button-link:focus {
  color: #00396c;
  text-decoration: none;
  box-shadow: none
}

a.button-link:active::before, a.button-link:hover::before, a.button-link:focus::before {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  border-bottom-color: #00396c
}

a.button-link:active {
  transform: scale(0.95);
  -webkit-transform: scale(0.95)
}



/* Buttons */

.btn {
  color: white !important;
  background: #316bbe !important;
  font-size: 16px !important;
  font-family: 'Enterprise-Bold', sans-serif !important;
  font-weight: 400 !important;
  letter-spacing: .04rem !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 16px !important;
  text-decoration: none !important;
  padding: 13px 20px !important;
  border-radius: 28px !important;
  border: 1px solid #316bbe !important;
  cursor: pointer !important;
  margin: 12px 12px 12px 0 !important;
  transition: all .1s linear !important;
  text-transform: none !important;
}

.btn:hover {
  color: white!important;
  background-color: #033465!important;
  border: 1px solid #033465!important;
  text-decoration: none!important;
}

.btn-white {
  color: #316bbe;
  background: white;
  border: 1px solid #316bbe;
}

.breathing-room {
  padding: 56px 0;
}

.breathing-room-top {
  margin-top: 56px;
}

.breathing-room-top-more {
  margin-top: 115px;
}

.breathing-room-bottom {
  margin-bottom: 56px;
}

.breathing-room-bottom-more {
  margin-bottom: 115px;
}

.light-bg {
  background-color: var(--color-1);
}

.home-carousel {
  height: 330px;
}

.home-slide {

  h1 {
    color: #FFFFFF;
  }

  p {
    color: #FFFFFF;
  }
}

.inner-header-hero {
  background-color: #efefee;
  position: relative;
  width: 100%;
  height: 330px;
  overflow: hidden;
  margin-bottom: 56px;
}

.inner-header-hero-content {
  display: block;
  max-height: 330px;
  width: 500px;
  padding: 20px 5% 20px 11%;

  h1 {
    font-size: 40px !important;
  }

  p {
    font-size: 23px;
    line-height: 28.77px;
    font-family: "Enterprise-Light", Arial, sans-serif !important;
  }
}

.inner-hero {
  background-color: #efefee;
  position: relative;
  width: 100%;
  height: 330px;
  overflow: hidden;
}

.inner-hero-content {
  display: block;
  max-height: 330px;
  width: 500px;
  padding: 20px 5% 20px 11%;

  h1 {
    font-size: 36px !important;
    line-height: 45px;
  }

  p {
    font-size: 15px;
    line-height: 24px;
  }


}

ul {

}

li {
  font-size: 15px;
  padding-bottom: 20px;
}

.border-bars {
  border-bottom: 1px solid #B7B7B7 !important;
  border-top: 1px solid #B7B7B7 !important;
  padding-bottom: 20px;
}

.border-bottom {
  border-bottom: 1px solid #B7B7B7 !important;
  padding-bottom: 20px;
}

.border-top {
  border-top: 1px solid #B7B7B7 !important;
  padding-bottom: 20px;
}

.divider {
  //padding: 16px;
  border-top: 1px solid #B7B7B7 !important;
}

.divider-top {
  margin-bottom: 56px !important;
  padding: 0 !important;
  border-top: 1px solid #B7B7B7 !important;
}

.divider-bottom {
  margin-top: 56px !important;
  //padding:0!important;
  border-top: 1px solid #B7B7B7;
}

.divider-middle {
  margin: 56px 0 !important;
  padding: 0 !important;
  border-top: 1px solid #B7B7B7;
}

.divider-broken {
  position: relative;
  margin-left: -9px;
  height: 60px;
}

.divider-broken:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  border-top: 1px solid #B7B7B7;
}

.arrow-down:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -5px;
  left: calc(50% - 9px);
  width: 28px;
  height: 18px;
  background-color: white;
  background-image: url("/img/standard/arrow-down.svg");
  background-position: center;
  background-repeat: no-repeat;
}

//Home Blocks
.block-container-1 {
  max-width: 1035px;
  z-index: 1;
  position: relative;
}

.block-1 {
  padding: 56px 40px;
  width: 444px;
  height: 440px;
}

.block-1-bg {
  height: 440px;
}

//Blocks 2
.block-container-2 {
  max-width: 1280px;

}

//Text Only Blocks
.block-container-3 {
  max-width: 1280px;

  h4 {
    line-height: 28.77px !important;
  }

  p {
    line-height: 24px;
  }

  hr {
    margin: 24px 0;
  }

}

//CTA
.block-container-4 {
  max-width: 1280px;

  h3 {
    //padding:24px 0 ;
  }

  h4 {
    font-size: 20px !important;
  }

  hr {
    margin: 30px 0 0 0;
  }

}


//Text Only Blocks - AC Specific
.block-container-5 {
  max-width: 1280px;

  h4 {
    line-height: 28.77px !important;
  }

  p {
    line-height: 24px;
  }

  hr {
    margin: 24px 0;
  }

}


.image-text-block--text {
  width: 600px;
}

.accordion {
  background-color: white !important;
  padding: 24px 0 24px 0 !important;


}

.gradient-bar {
  display: block;
  height: 5px;
  /* background-image: url("assets/img/gradient-bar.png"); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  //padding: 0.5rem 0 0.5rem 0;
  margin: 0 1rem;
}

.accordion-item {
  margin: auto 12px;
}

.accordion-item-header {
  padding: 0.5rem 0 0.5rem 0;
  margin: 0 1rem;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  font-family: "Enterprise-Light", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #000000 !important;
  border-bottom: 1px solid #000000;
}

.accordion-item-header.active {
  color: #C25608 !important;;
}

.accordion-item-header::after {
  color: #B7B7B7;
  font-family: 'FontAwesome';
  content: "\f078";
  font-size: 12px;
  position: relative;
  left: 12.5px;
}

.accordion-item-header.active::after {
  font-family: 'FontAwesome';
  content: "\f077";
}

.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-item-body-content {
  margin: 0 1rem;
  padding: 16px 0 !important;
  font-family: 'Enterprise', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;

  ul {

  }

  li {
    font-size: 15px !important;
    padding-bottom: 0 !important;
  }
}

#accordion-close {
  padding-bottom: 16px;
  margin: 0 1rem;
  color: #C25608;
  border-bottom: 1px solid #C25608;
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.08em;
}


/* Cards */

/* Cards with border */

.cards-no-border {
  max-width:1350px;
  .card {

    padding: 0 !important;
    border: none !important;
    border-radius: 0;


    .card-body {
      padding: 0 !important;

      .card-img-top {
        width: 100%;
        //height: 55vw;
        height: auto;
        object-fit: cover;
      }

      img {
        border-radius: 0;
      }

      h4 {
        font-family: "Enterprise-Light", Arial, sans-serif !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 18px !important;
        line-height: 23px !important;
        margin: 24px 0 16px 0 !important;
      }

      a::after {
        //margin-right:20px;

      }
    }
  }
}

.cards-with-border {

  .card {
    width: 330px;
    //height: 175px;
    height: 266px;
    padding: 10px 10px;
    border-radius: 0.5px;

    .card-body {
      h4 {
        margin-bottom: 24px;
      }

      a {

      }
    }
  }
}


.carousel-indicators {
  li {
    padding-bottom: 0;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 36px;
  width: 36px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #3E6CB8;
  background-image: none;
  color: #3E6CB8;
  padding-top: 7px;

}

.carousel-control-next-icon:after {
  font: normal normal normal 20px/1 FontAwesome;
  content: "\f061";
  color: white;
}

.carousel-control-prev-icon:after {
  font: normal normal normal 20px/1 FontAwesome;
  content: "\f060";
  color: white;
}

.provider-single {
  img {
    height: 415px;
    width: auto;
  }

  .info {
    padding: 40px !important;

    i {
      font-size: 22px;
      width: 26px;
    }

    .border-bottom {
      padding-bottom: 24px;
    }

    .border-top {
      border-top: 1px solid #B7B7B7 !important;
      padding-top: 20px;
    }
  }
}

/* Menu On-Click Body Overlay */
.body-overlay:after {
  content: "";
  display: block;
  position: absolute; /* could also be absolute */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, .5)
}



/*Misc AC */
.center-text-columns {
  text-align: center !important;

  ul {
    list-style-type: none;
    margin: 20px 0;
    padding: 0;

    li {
      // margin: -12px;
      font-size: 12px !important;
      line-height: 19px;
    }
  }
}

.phone-number-list {
  padding-top: 20px;

  p {
    padding: 10px;
  }
}

.phone-number {
  white-space: nowrap;
  font-weight: bold;
}

.link-spacing {
  ul {
    padding: 0;
    list-style-type: none;

    li {
      padding: 5px 0;
      font-family: 'Enterprise', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 19px;
      color: #000;
    }
  }
}



/* Style.css */
/*  */


/* utility Classes */

.no-padding{
  padding:0!important;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-a {
  display: flex;
  align-items: center;
  // justify-content: center;
}

.white {
  color: #ffffff;
}

.text-spacer {
  padding: 35px 0;
}


.break {
  flex-basis: 100%;
  height: 0;
}

.inner-content {
  padding: 35px 35px 35px 35px;
}



.home-cards {
  padding: 48px 0;
}

.bg-grey {
  background-color: #EFEFEE !important;
}

.bg-dark-grey {
  background-color: #63666A !important;
}

.red-text {
  color: #DF3E0F !important;
}

.red-alert {
  width: 65%;
  background-color: #DF3E0F;
  height: 110px;
  margin: auto;
}

.red-alert p {
  font-family: "Enterprise-Light", Arial, sans-serif;
  color: white;
  font-size: 14px !important;
}

.red-alert h4 {
  font-family: 'Enterprise', sans-serif !important;
  font-weight: bold !important;
  color: white;
  font-size: 15px !important;
  letter-spacing: 0.41px !important;

}

.red-alert i {
  // word-break: break-all;
}

.white-bg {
  background-color: #FFFFFF !important;
}

.divider-1 {
  border-top: 6px solid rgb(206, 75, 39);
  width: 60px;
  margin-left: 0px;
}

/* Main body */
.hero-box {
  width: 70%;
  max-width: 550px;
  // height: 382 px;
  // max-height: 382 px;
  background-color: rgba(255, 255, 255, .9);
  padding: 40px 60px 40px 60px;
  margin-left: 120px;
}

.hero-box h1 {
  text-align: center;
}

.hero-box p {
  font-family: "Enterprise-Light", Arial, sans-serif !important;
  letter-spacing: .04rem;
  font-size: 20px !important;
}


.hero-mobile {
  display: none !important;
}

.row {
  // margin: 0 px !important;
}





/* Universal */

p, li, .blue-link, .orange-link, body, .orange-link {
  color: #434448 !important;
  font-size: 15px !important;
  font-family: 'Enterprise', sans-serif !important;
  letter-spacing: 0.41px;
}

body {
  font-family: 'Enterprise', sans-serif !important
}

body a {
  color: #316bbe;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Enterprise', sans-serif;
  font-weight: normal;
}

body a:hover {
  text-decoration: underline;
}

.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: none;
}


.text-center {
  margin: auto;
}

.orange-btn {
  font-weight: 300 !important;
  padding: 15px 20px;
  border: 1px solid #707070;
  margin-top: 15px;
  background-color: rgb(194, 86, 8);
  color: white;
}

.blue-btn {
  letter-spacing: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  text-decoration: none !important;
  padding: 13px 20px;
  min-width: 186;
  border-radius: 28px;
  border: 1px solid #316bbe;
  cursor: pointer;
  color: #fff;
  margin: 4px 0;
  background: #316bbe;
  background-clip: border-box;
  background-clip: padding-box;
  transition: all .1s linear;
  text-transform: none;
}

.blue-btn:hover {
  background: #00396c;
  border-color: #00396c;
  box-shadow: 0 0 0 2px #00396c;
  text-decoration: none !important;
}

.no-underline {
  text-decoration: none !important;
}

.bold {
  font-weight: bold !important;
}




.centered-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-div-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.centered-div-left {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}



/* Home page edits */

.test-row {
  height: 300px;
}

.home-hero {
  /* background-image: url("assets/img/background-img.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 382px;
  max-height: 382px;
}

.home-hero h1 {
  font-family: "Enterprise-Light", Arial, sans-serif;
  font-size: 36px;
  letter-spacing: .04rem;
}

.sub-div-welcome {
  max-width: 550px;
  max-height: 350px;
  background-color: rgba(255, 255, 255, .9);
  padding: 40px 60px 40px 60px;
  margin-left: 15px;
}

.home-card-section {
  // padding: 80 px 0;
  // max-width: 1350 px;
}

.card-img-top-home {
  width: 100%;
}


.hero-right-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 295px;
}

.hero-right-image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}




.blue-link {
  color: #006DAC;
}

.orange-link {
  color: #C25608;
  font-weight: bold !important;
}

.orange-link:hover {
  color: #C25608;
  text-decoration: none;
}

.card-title {

}

.card-title-home {

}

.card-text {
}

.care-located {
  padding: 0px 20px;
}

.care-located ul li {
  margin-left: -20px;
  margin-bottom: 20px;
}

.care-located-img {
  width: 100%;
}

.we-are-here-for-you {
  padding: 0px 20px;
}

.start-off-right {
  background-color: white;
  border-bottom: 0px solid #000;

}

.start-off-right ul li {
  margin-bottom: 12px;
  margin-left: -35px;
}

.start-off-right p {
  margin-top: 15px;
}

.start-off-right-img {
  width: 100%;
}

.start-off-right .check-list {
  margin-top: -10px;
  margin-left: 20px;
  list-style-type: none;
}


.start-off-right .check-icon:before {
  font-weight: 900;
  font-family: 'Font Awesome\ 5 Free';
  content: "\f14a"; /* FontAwesome Unicode */
  position: absolute;
  left: 15px;
  color: #C25608;
}

.support {
  border-bottom: none;
  background-color: white;
}

.support ul li {
  margin-bottom: 0;
  margin-left: -20px;
}

.support-img {
  width: 100%;
}

.medical-records {
  background-color: white;
  border-bottom: 0px solid #000;
}

#now-what {
  /* background-image: url("assets/img/now-what-hero.png"); */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 30vh;
}

.find-care-card-body {
  display: block;
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.find-care-cards .card {
  border: none !important;
  padding: 10px;
}


.card-body-home {
  padding: 20px;
}

.ad-text {
  width: 100%;
  background-color: #505152;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.ad-text a {
  color: white !important;
}

.ad-text-div {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ad-text h2 {
  color: #FFFFFF;
  font-size: 2em;
  font-weight: bold;

}

.ad-text h4 {
  color: white;
  font-size: 1.5em;
  font-weight: normal;
  text-align: left;
}

.our-family-section {
  background-color: rgb(239, 239, 238);
  border-bottom: 0px solid #000;
  height: 100% !important;
  min-height: 800px;
}

.our-family-section .logo-group-1 {
  margin-top: 15px;
}

.our-family-section .logo-group-2 {
  margin-top: 45px;
}

.our-family-section .logo-group-3 {
  margin-top: 45px;
}

.our-family-heading {
  text-align: center;
}

.our-family-heading h3 {
  font-size: 42px;
}

.logo-icon img {
  width: 15em;
}

.why-optum {
  background-color: white;
  border-bottom: 0px solid #000;
}

.who-we-are {
  background-color: white;
}


.who-we-are ul {
  margin-left: -20px;
}

.who-we-are li {
  margin-bottom: 0px;
}


.Commitment {
  padding: 20px;
}

.footer-fluid {
  background-color: #282A2E;
}

.footer-top {
  font-family: 'Enterprise', sans-serif !important;
  margin: 60px auto 20px auto;
  max-width: 1200px;
}

.footer-top a {
  font-family: 'Enterprise', sans-serif !important;
  text-decoration: none !important;
  font-size: 12px !important;;
  color: #282A2E !important;;
  font-weight: bold !important;;
}

.footer-top .col-md-12 {
  text-align: center;
}

.footer-top a:hover {
  text-decoration: underline !important;
}

.footer {
  font-family: 'Enterprise', sans-serif !important;
  background-color: #282A2E;
  width: 100%;
  max-width: 1350px;
  height: auto;
  line-height: 30px;
  margin: auto;
}

.footer span {
  color: white;
  font-size: 12px;
  letter-spacing: 1.5px;
}

.footer a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  margin-left: 30px;
  font-weight: normal;
  font-family: 'Enterprise', sans-serif !important;
  letter-spacing: 1.5px;
  line-height: 1em;
}

.footer a:hover {
  color: white;
  text-decoration: underline;
}

.text-danger {
  color: #C25608 !important;
}

p.text-info {
  font-size: 16px;
  font-weight: 400;
  color: #505152 !important;
}

/* Responsive Styling */

@media (max-width: 576px) {
  html {
    border: none !important;
  }

  .header-logo img {
    max-width: 100px;
  }

  .nav-adjust {
    margin-left: -20px !important;
  }

  .home-cards {
    padding: 0 !important;
  }

  .hero-box {
    width: 100% !important;
    padding: 30px 20px 30px 20px;
  }

  .red-alert {
    width: 100% !important;
    background-color: #DF3E0F;
    height: 100% !important;
    padding: 20px 0;
  }

  .red-alert a {
    width: 40% !important;
    margin: auto;
  }

  .ad-text h2 {
    font-size: 1.3em !important;
  }

  .ad-text h4 {
    font-size: 1em !important;
  }

  .gray-cta {
    max-height: none;
    background-color: #505152;
    color: #fff;
    padding: 100px 0 !important
  }

  .gray-cta h2 {
    font-size: 1.3em !important;
  }

  .gray-cta h4 {
    font-size: 1em !important;
  }

  .accordion .card-header {
    text-align: left;


  }

  .accordion a.card-title {
    display: inline-block;
    width: 90%;
    text-align: left;
  }

  .card {
    margin-bottom: 30px;
  }

  .mobile-row-1 img {
    margin-top: 15px;
  }

  .mobile-row-2 {
    margin-top: 0px;
  }

  .mobile-row-1 img, .mobile-row-2 img {
    margin-bottom: 15px;
  }

  .card-img-top-home {
    width: 100%;
  }

  #now-what, .gray-hero {
    height: 295px;
  }

  .hide-mobile {
    display: none;
  }

  .home-card-section {
    padding: 0 10px !important;
  }

  .home-card-section h2 {
    font-size: 2em;
  }

  .home-card-left, .home-card-right {
    height: 100%;
  }

  .who-we-are, .care-located, .we-are-here-for-you {
    padding: 20px 0 !important;
  }

  .who-we-are ul li {
    margin-left: 10px;
  }

  .our-family-heading {
    text-align: left !important;
  }

  .our-family-heading h3 {
    font-size: 2em !important;
  }

  .care-located ul li {
    margin-left: 10px;
  }

  .we-are-here-for-you ul li {
    margin-left: 10px;
  }

  .flip-mobile {
    flex-direction: column-reverse;
  }

  .footer a {
    margin: 0px 15px !important;
  }

  .footer-text-centered {
    text-align: center;
  }

}


@media (max-width: 640px) {


  .flip-mobile {
    flex-direction: column-reverse;
  }

  .hero-right-image {
    height: 295px !important;
  }

  .home-card-section {
    padding: 60px 20px !important;
  }

  .gray-cta {
    height: 60vh !important;
    padding: 0px 10px !important;
  }

  .card {
    margin-top: 0 !important;
  }

  .nav-item {
    text-align: left;
    padding-left: 15px;
  }

  .mobile-row-1 img, .mobile-row-2 img {
    margin-bottom: 15px;
  }

  .hide-mobile {
    display: none;
  }

  .text-right {
    margin-top: 5px;
  }

  .mobile-link-center {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {


  .flip-mobile {
    flex-direction: column-reverse;
  }

  .card-img-top {
    height: 200px;
  }

  .card-title-home {
    // height: 40 px;
  }

  .home-card-left, .home-card-right {
    // max-height: 700 px;
  }

  .logo-group-2, .logo-group-3 {
    margin-top: 0px !important;
  }

  .logo-icon {
    padding: 30px 0;
  }

  .logo-icon img {
    width: 15em;
  }

  .our-family-section {
    min-height: 100% !important;
    padding: 50px 40px;
  }

  .phone-number {
    line-height: 1.6 !important;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  .home-card-left {
    margin-bottom: 45px;
  }
}

@media (min-width: 981px) {
  .hide-desktop {
    display: none;
  }

  .text-right {
    margin-top: 0px;
  }

  .inner-hero {
    max-height: 100% !important;
  }
}

@media (max-width: 990px) {

  .bottom-nav {
    min-height: 0 !important;
    position: relative !important;
    top: -40px !important;
    border-bottom: none !important;
    padding: 0px !important;
    margin-bottom: -20px !important;
  }

  .top-nav-line {
    border: none;
  }

  .mobile-row-1 img, .mobile-row-2 img {
    margin-bottom: 15px;
  }

  .nav-item {
    text-align: left;
    padding-left: 15px;
  }

  .mobile-row-2 {
    margin-top: -60px;
  }

  .flip-tablet {
    flex-direction: column-reverse;
  }

  .hide-mobile {
    display: none;
  }

  .flip-mobile {
    flex-direction: column-reverse;
  }

}

@media (max-width: 991px) {

  .home-hero {
    max-height: 100% !important;
    height: 100% !important;
    // background-position-x: - 950 px !important;
  }

  .home-hero-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub-div-welcome {
    max-height: 90% !important;
    max-width: 90% !important;
    background-color: rgba(255, 255, 255, .9);
    padding: 40px 60px 40px 60px;
    margin: 15px 0 !important;
  }

  .mobile-center {
    text-align: center;
  }

  .card-img-top-home {
    width: 100%;
  }

  .home-card-right {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-card-left {
    padding-right: 15px;
    padding-left: 15px;
  }

  .card-title-home {
    // height: 60 px;
  }

  .sub-div-welcome {
    width: 100%;
  }

  #now-what, .gray-hero {
    padding: 20px 20px 20px 20px !important;
  }
}


@media (min-width: 1020px) {


  .home-card-right {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-card-left {
    padding-right: 15px;
    padding-left: 15px;
  }


}

@media (max-width: 1200px) {

  .header-logo {
    // padding-left: 40 px !important;
  }

  .nav-adjust {
    margin-left: 0 !important;
  }

  .hero-box {
    width: 100%;
    margin-left: 0 !important;
  }

  .footer a {
    margin: 0px 5px;
  }

}


@media (min-width: 1200px) {
  .card-img-top-home {
    width: 100%;
  }


}


/* Preloader */
.pre-loader {
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

/* Vaccine scss */
.phases-section {
  padding: 48px 156px;
  background-color: #ffffff;
}

.dot {
  height: 67px;
  width: 67px;
  background: #df3926 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-block;
}

.dot .text {
  display: block;
  padding: 10px;
  text-align: center;
  font-family: "Enterprise-Light", Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.63px;
  color: #ffffff;
  opacity: 1;
}

.timeline-list {
  padding: 0 0 45px 0;
}

.timeline-list ul {
  margin: auto;
}

.timeline-list ul li {
  color: white;
  margin-bottom: 20px;
}

.phase-1a-line {
  height: 37px;
  border-top: none !important;
  border: 1px solid #656565;
  opacity: 1;
}

.phases {
  padding: 80px 156px;
}

.phase-row {
  margin-bottom: 44px;
}

.phase-gutters {
  margin-right: 4px;
  margin-left: 4px;
}

.phase-gutters-inner {
  margin-right: -4px;
  margin-left: -4px;
}

.phase-col {
  padding-right: 4px;
  padding-left: 4px;
}

.phase-col ul {
  padding-top: 5px;
}

.phase-col ul li {
  font-size: 15px !important;
  font-family: "Enterprise", sans-serif !important;
  padding-top: 2px;
}

.phase-col-inner {
  margin-top: 8px;
  padding-right: 4px;
  padding-left: 4px;
}

.phase-heading {
  text-align: center;
  font-family: "Enterprise-Light", sans-serif;
  font-weight: normal;
  font-size: 39px;
  letter-spacing: -1.36px !important;
  color: #ffffff;
  opacity: 1;
}

.phase-sub-heading {
  font-family: "Enterprise-Light", sans-serif;
  text-align: center;
  font-size: 25px;
  letter-spacing: -0.88px;
  color: #ffffff;
  opacity: 1;
}

.phase-sub-text {
  font-family: "Enterprise-Light", sans-serif;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.63px;
  color: #ffffff;
  padding: 0 20px;
}

.phase-1a {
  background: transparent linear-gradient(263deg, #faa811 0%, #c3373f 100%) 0%
  0% no-repeat padding-box;
  opacity: 1;
}

.phase-1b {
  background: transparent linear-gradient(261deg, #8061bc 0%, #033465 100%) 0%
  0% no-repeat padding-box;
  opacity: 1;
}

.tier-1 {
  background: var(--unnamed-color-0b5e9d) 0% 0% no-repeat padding-box;
  background: #0b5e9d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.tier-2 {
  background: var(--unnamed-color-8061bc) 0% 0% no-repeat padding-box;
  background: #8061bc 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
}

.phase-1c {
  background: transparent linear-gradient(251deg, #3bb6a7 0%, #01685c 100%) 0%
  0% no-repeat padding-box;
  opacity: 1;
}

.phase-2 {
  background: transparent
  linear-gradient(249deg, #c7383d 0%, var(--unnamed-color-422c88) 100%) 0% 0%
  no-repeat padding-box;
  background: transparent linear-gradient(249deg, #c7383d 0%, #422c88 100%) 0%
  0% no-repeat padding-box;
  opacity: 1;
}

.additional-resources {
  width: 70%;
  margin: auto;
}

/* Internal Pages */
.get-vaccinated-hero-box {
/* background-image: url("assets/img/get-vaccinated.png") !important; */
}

.locations {
margin-bottom: 32px;
}

.orange-heading {
font-family: "Enterprise", sans-serif !important;
letter-spacing: 2px !important;
color: #c25608 !important;
opacity: 1 !important;
font-size: 20px;
}

.dot-line {
padding-top: 15px;
font-weight: bold;
}

.county-heading {
padding: 60px 0 57px 0;
}

.locations h3 {
padding-bottom: 58px;
}

.locations h4 {
font-family: "Enterprise-Light", sans-serif !important;
font-size: 23px;
letter-spacing: 0px;
}

.address {
margin-top: -25px;
padding-left: 25px;
}

.address p {
max-width: 95%;
margin-top: -15px;
letter-spacing: 0px;
}

.dot-green {
height: 15px;
width: 15px;
background-color: #90bc53;
border-radius: 50%;
display: inline-block;
}

.dot-orange {
height: 15px;
width: 15px;
background-color: #f2b411;
border-radius: 50%;
display: inline-block;
}

.dot-red {
height: 15px;
width: 15px;
background-color: #df3e0f;
border-radius: 50%;
display: inline-block;
}

.last-row-border {
padding-bottom: 60px;
border-bottom: 1px solid #707070;
}

.resources {
width: 90%;
margin: auto;
padding-top: 20px;
}

.resources button {
background: #fff !important;
font-size: 18px;
font-family: "Enterprise-Bold", sans-serif !important;
font-weight: normal;
letter-spacing: 0.04rem !important;
justify-content: center;
align-items: center;
line-height: 26px;
text-decoration: none !important;
padding: 15px 2px;
border-radius: 30px;
border: 1px solid #316bbe;
cursor: pointer;
margin: 4px 0;
transition: all 0.1s linear;
text-transform: none;
color: #316bbe;
}

.resources button:hover {
background-color: #316bbe !important;
color: #fff !important;
border: 1px solid #fff;
text-decoration: none !important;
}

.resources button:active,
button:visited,
button:focus {
background: #fff !important;
color: #316bbe !important;
border: 1px solid #316bbe !important;
text-decoration: none !important;
}

.resources a:hover {
text-decoration: none !important;
}

.contact-us-hero-box {
/*background-image: url("assets/img/contact-us.png") !important; */
}

.contact-us-hero-box p {
font-size: 15px !important;
}

.contact-us-hero-box h2 {
font-family: "Enterprise-Light", sans-serif !important;
font-size: 22px !important;
letter-spacing: 2.2px;
}

.contact-us {
width: 80%;
height: 262px;
border-bottom: 1px solid #707070;
margin: auto;
}

.contact-us .heading {
font-family: "Enterprise-Light", sans-serif !important;
display: block;
font-size: 23px !important;
margin: auto;
}

.contact-us .sub-heading {
font-family: "Enterprise", sans-serif !important;
display: block;
font-size: 15px !important;
padding-top: 15px;
margin: auto;
padding-bottom: 15px;
}

@media (max-width: 576px) {
.timeline-list ul {
  margin-left: -20px !important;
}

.resources {
  width: 100%;
  margin: auto;
  padding-top: 0;
}

.additional-resources div {
  padding-top: 30px;
}

.contact-us {
  width: 100% !important;
  height: 400px !important;
}
}

@media (max-width: 1200px) {
.phases-section {
  padding: 80px 20px;
}

.phases {
  padding: 80px 20px;
}

.locations {
  margin-bottom: 0 !important;
}

.locations .col-lg-4 {
  padding-bottom: 25px;
}
}

